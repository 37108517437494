<template>
    <div style="height: 100%;">
        <div class="login-container">
            <div class="login-photo"></div>
            <div class="login-border"></div>
            <div class="login-form">
                <div class="login-title">
                    <p class="greetings">Hello!</p>
                    <p class="welcome">
                        欢迎来到
                        <span class="jd-course">一课云平台</span>
                    </p>
                </div>
                <el-form :model="loginForm" :rules="loginRules" hide-required-asterisk ref="loginForm" class="login-content">
                    <el-form-item prop="admin_account">
                        <el-input v-model="loginForm.admin_account" placeholder="手机号码/用户名" @keyup.enter.native="submitForm('loginForm')">
                            <i slot="prefix" class="iconfont">&#xe8c9;</i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="admin_password">
                        <el-input v-model="loginForm.admin_password" placeholder="密       码" type="password" show-password @keyup.enter.native="submitForm('loginForm')">
                            <i slot="prefix" class="iconfont">&#xe8d4;</i>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="submit-btn" @click="submitForm('loginForm')">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <FooterModule></FooterModule>
    </div>
</template>

<script>
    import FooterModule from 'components/qualityCourseLayout/FooterModule';
    import {Swiper, SwiperSlide} from "vue-awesome-swiper";
    export default {
        data() {
            return {
                loginForm: {
                    admin_account: '',
                    admin_password: ''
                },
                loginRules: {
                    admin_account: [
                        { required: true, message: '请输入用户名', trigger: 'blur' }
                    ],
                    admin_password: [
                        { required: true, message: '请输入密码', trigger: 'blur' }
                    ],
                },
            }
        },
        components: {
            FooterModule
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('s_account', this.loginForm.admin_account);
                        formData.append('s_password', this.loginForm.admin_password);
                        this.$shttp.axiosPost(this.$api.JdStudentLogin, formData, (res) => {
                            if (res.code === 200) {
                                localStorage.setItem('sToken', res.data.token);
                                localStorage.setItem('sUsername', res.data.userInfo.s_name);
                                localStorage.setItem('role', res.data.role);
                                localStorage.setItem('sIcon', res.data.userInfo.s_ico);
                                localStorage.setItem('c_id', res.data.userInfo.c_id);
                                localStorage.setItem('s_id', res.data.s_id);
                                localStorage.setItem('ip', res.data.ip);
                                localStorage.setItem('softUrl', res.data.softUrl);
                                // this.$router.push('/myclass/detail');
                                this.$router.push('/qualityCourse/courseindex');
                                // this.$message.success('登录成功！')
                            } else {
                                this.$message.warning(res.msg);
                            }
                        })
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .login-container {
        /*width: 1200px;*/
        /*min-width: 1200px;*/
        margin: 0 auto;
        display: flex;
        min-height: calc(100% - 84px);
        justify-content: center;
        background: #fff;
        .login-photo {
            width: 612px;
            height: 422px;
            margin-top: 100px;
            background: url("../../assets/images/login_img.png") no-repeat 0 0;
            background-size:  80% 80%;
            margin-left: 94px;
        }
        .login-border {
            width: 52px;
            margin-left: -50px;
            margin-top: -30px;
            background: url("../../assets/images/border.png") no-repeat 0 0;
        }
        .login-form {
            margin-bottom: 60px;
            width: 340px;
            .login-title {
                margin-top: 120px;
                .greetings {
                    font-size: 28px;
                    margin: 0 0 1px;
                }
                .welcome {
                    color: #888;
                    font-size: 18px;
                    margin: 0 0 15px;
                    .jd-course {
                        color: #ff9204;
                    }
                }
            }
            .login-content {
                .el-form-item {
                    margin-bottom: 32px;
                }
                ::v-deep .el-input {
                    .el-input__inner {
                        height: 45px;
                        line-height: 43px;
                        padding: 0 40px;
                        border-radius: 3px;
                        background: #f4f4f4;
                        border: 1px solid #c1c1c1;
                        &:focus {
                            color: #495057;
                            background-color: #fff;
                            outline: none;
                            border-color: #4A90E2;
                            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
                        }
                    }
                    .el-input__prefix {
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        transform: translateY(-50%);
                        display: flex;
                        align-items: center;
                        .iconfont {
                            font-size: 20px;
                        }
                    }
                }
                .submit-btn {
                    width: 100%;
                    font-size: 16px;
                    height: 45px;
                    border-radius: 0;
                    border: none;
                    background: linear-gradient(to bottom right, #F35552 , #E31D1A);
                }
            }
        }
    }
</style>